import InspirationBehindNodevin from "blog/posts/20240715-InspirationBehindNodevin";
import WorkingAtNodevin from "blog/posts/20240709-WorkingAtNodevin";
import PowerOfTools from "blog/posts/20240824-PowerOfTools";
import DecentralizationRequiresAccessibility from "blog/posts/20241001-DecentralizationRequiresAccessibility";
import PracticalVsReligiousDecentralization from "blog/posts/20241007-PracticalVsReligiousDecentralization";
import WhatWeStandFor from "blog/posts/20241011-WhatWeStandFor";
import HowNodevinWorks from "blog/posts/20241122-HowNodevinWorks";
import DecentralizingKnowledge from "blog/posts/20250102-DecentralizingKnowledge";
import MemesAsCurrency from "blog/posts/20250106-MemesAsCurrency";
/*
import DecentralizationGrant from "./posts/20241203-DecentralizationGrant";

{
  title: "The Decentralization Grant - Anonymous",
  date: "December 3rd, 2024",
  excerpt: "A $10,000 grant toward accessible decentralization by an anonymous benefactor.",
  content: <DecentralizationGrant />,
  category: "Grants",
},
*/

const blogPosts = [
    {
      title: "Memes as Decentralized Currency",
      date: "January 6, 2025",
      excerpt: "Exploring the intersection of memes, cultural value, and decentralized currencies.",
      content: <MemesAsCurrency />,
      category: "Technology",
    },
    {
      title: "Decentralizing Blockchain Knowledge",
      date: "January 2, 2025",
      excerpt: "Exploring the importance of education and awareness in achieving true blockchain decentralization.",
      content: <DecentralizingKnowledge />,
      category: "Technology",
    },
    {
      title: "How Nodevin Works",
      date: "November 22, 2024",
      excerpt: "Insight into the technical components powering Nodevin's functionality.",
      content: <HowNodevinWorks />,
      category: "Technology",
    },
    {
      title: "What We Stand For",
      date: "October 11, 2024",
      excerpt: "Short points that describe what Nodevin stands for.",
      content: <WhatWeStandFor />,
      category: "Vision",
    },
    {
      title: "Practical vs. Religious Decentralization",
      date: "October 7, 2024",
      excerpt: "The same idea — a democratic DAO — can manifest entirely separate systems based upon its approach.",
      content: <PracticalVsReligiousDecentralization />,
      category: "Other",
    },
    {
      title: "Why Decentralization Requires Accessibility",
      date: "October 1, 2024",
      excerpt: "True decentralization is achieved with accessible tools.",
      content: <DecentralizationRequiresAccessibility />,
      category: "Vision",
    },
    {
      title: "The Power of Human Tools",
      date: "August 24, 2024",
      excerpt: "In many ways, humans have moved beyond the natural limits that once defined their existence.",
      content: <PowerOfTools />,
      category: "Other",
    },
    {
      title: "Inspiration Behind Nodevin",
      date: "July 15, 2024",
      excerpt: "Answering questions about the origin and inspiration for Nodevin.",
      content: <InspirationBehindNodevin />,
      category: "Vision",
    },
    {
      title: "Working at Nodevin",
      date: "July 9, 2024",
      excerpt: "Reality must be created, thus it is our duty to act.",
      content: <WorkingAtNodevin />,
      category: "Vision",
    },
  ];
  
  export default blogPosts;
  